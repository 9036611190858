import React, { Component } from 'react';
import { SocialIcon } from 'react-social-icons';

class Footer extends Component {


	render() {

		return (

			<div class="footer">
				<div class="footer2">
				<div class="copyright">Copyright © 2022, CARTOONISTICS</div>
				<div class="copyright2">All Right Received</div>
				</div>
			</div>)
	}
}

export default Footer;