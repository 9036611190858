import React, { Component } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Home from "./Pages/Home";
import About from './Pages/about';
import Enter from './Pages/enter';
import Lelouvre from './Pages/lelouvre';
  
function App() {

	return (
		<div>
			<BrowserRouter>
				<Routes>

					<Route path='/' element={<Enter />} />
					<Route path='/home' element={<Home />} />
					<Route path='/lelouvre' element={<Lelouvre />} />
 					<Route path='/about' element={<About />} />

				</Routes>
			</BrowserRouter>
			<div>
		

			</div>
		</div>




	)
}


export default App;
