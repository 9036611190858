import '../App.css';
import "aos/dist/aos.css";
import React, { Component } from 'react';
import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import discord from '../assets/discord.png';
import twitter from '../assets/twitter.png';
import $ from 'jquery';
import audio from '../audio/thanos.mp3';
import video2 from '../assets/2.mkv';
import login from '../assets/login.png';
import CoinbaseWalletSDK, { CoinbaseWalletProvider } from "@coinbase/wallet-sdk";

let account;
let mintAmount = 1;
let valueOfNFTs = 0;
let totalSupplyNFT;
let totalSupplyNFT2;
let maxMintNFTs;
let onlyLeft;
let owner;
let publicSale;
let user_input;
let wMintAmount;
let myTokens = [];
let myTokens2 = [];
let adminWhitelistAddresses = [];
let adminPanel = 0;
let adminWhitelistAddresses2 = [];
let maxTokensToBuy = "";
let testingvalue;
let wlMint;
let FinalResult;
let wlMsg = "Whitelist Mint";
let mintStatus = ''
let cost = '';
let ferrisWheel;
let wrongMsgs = [
	"Pat ordered a ghost pepper pie.",
	"This made him feel like an old-style rootbeer float smells.",
	"Happiness can be found in the depths of chocolate pudding.",
	"He was sitting in a trash can with high street class.",
	"The tears of a clown make my lipstick run, but my shower cap is still intact.",
	"I think I will buy the red car, or I will lease the blue one.",
	"Nancy was proud that she ran a tight shipwreck.",
	"He shaved the peach to prove a point.",
	"The stench from the feedlot permeated the car despite having the air conditioning on recycled air.",
	"It didn't make sense unless you had the power to eat colors.",
	"I became paranoid that the school of jellyfish was spying on me.",
	"It was difficult for Mary to admit that most of her workout consisted of exercising poor judgment.",
	"It was the best sandcastle he had ever seen.",
	"It's never comforting to know that your fate depends on something as unpredictable as the popping of corn.",
	"Art doesn't have to be intentional.",
	"He didn't heed the warning and it had turned out surprisingly well.",
	"I want to buy a onesie… but know it won’t suit me.",
	"His son quipped that power bars were nothing more than adult candy bars.",
	"He realized there had been several deaths on this road, but his concern rose when he saw the exact number.",
	"It isn't true that my mattress is made of cotton candy.",
	"Now I need to ponder my existence and ask myself if I'm truly real",
	"It was always dangerous to drive with him since he insisted the safety cones were a slalom course.",
	"Even with the snow falling outside, she felt it appropriate to wear her bikini.",
	"He liked to play with words in the bathtub.",
	"The heat You've been eyeing me all day and waiting for your move like a lion stalking a gazelle in a savannah.",
	"For the 216th time, he said he would quit drinking soda after this last Coke.",
	"She discovered van life is difficult with 2 cats and a dog.",
	"The sign said there was road work ahead so he decided to speed up.",
	"With a single flip of the coin, his life changed forever.",
	"Grape jelly was leaking out the hole in the roof.",
	"We should play with legos at camp.",
	"It doesn't sound like that will ever be on my travel list.",
	"Improve your goldfish's physical fitness by getting him a bicycle.",
	"The llama couldn't resist trying the lemonade.",
	"The toddler’s endless tantrum caused the entire plane anxiety.",
	"It was obvious she was hot, sweaty, and tired.",
	"The hawk didn’t understand why the ground squirrels didn’t want to be his friend.",
	"If I don’t like something, I’ll stay away from it.",
	"Carol drank the blood as if she were a vampire.",
	"I'm confused: when people ask me what's up, and I point, they groan.",
	"The two walked down the slot canyon oblivious to the sound of thunder in the distance.",
	"He said he was not there yesterday; however, many people saw him there.",
	"The beauty of the African sunset disguised the danger lurking nearby.",
	"She was disgusted he couldn’t tell the difference between lemonade and limeade.",
	"This book is sure to liquefy your brain.",
	"He dreamed of eating green apples with worms.",
	"Honestly, I didn't care much for the first season, so I didn't bother with the second.",
	"The glacier came alive as the climbers hiked closer.",
	"Two more days and all his problems would be solved.",
	"The miniature pet elephant became the envy of the neighborhood.",
	"Having no hair made him look even hairier."
];

let correctMsgs = [
	"You can count. Don't be so proud. Still, tell the Cartoonist. He'd be interested.",
	"Huh. Didn't expect you to figure that out. But anyways, do you read?",
	"It's always the last breath that draws the most meaning. Published by New York and Boston H. M. Caldwell Company Publishers.",
	"Long one wasn't it? That one took you years to write. Almost like you gave birth. But you didn't, someone else did. #=years",
	"I love bold, italics, and underline. But my FIRST option would be italics.",
	"Wow. You're good. Go tell the Cartoonist the last thing you wrote. You are a star."
];
let e;
let soundfinder;
let song;
let myTokenIDs = [];
let myPuzzleIDs = [];
let elementArray = [];
let biddingMiddlePiece;
let winningValue = 0;
let biddingClosingTimeByOwner = 0;
let bidDepositer;
let bidDepositedList = [];
let bidAmountsList = [];
let bidFullList = [];
let biddingInfo = 0;
let biddingNote = 0;
let gap = 0;
let nextValue = 0;
let costBidding = "";
let fullPuzzles = [];
let puzzlePieceIDs = "";
let pieceID = [];
let middlePieceIDUrl;
let ownerOfTheMiddlePieceOwner;
let pieceID2 = [];
let roundCount = 0;
let mintingAllowed2 = true;
let finalMintingPieces = [];
let bidCount;
let winnerNickname;
let nickNameWithWallet;
let nickNameWithWalletCall;
let nickNameList = [];
let filteredMiddleList = [];

var count = 1;

// 1. Import libraries. Use `npm` package manager to install
const { MerkleTree } = require('merkletreejs');
const keccak256 = require('keccak256');

// 2. Collect list of wallet addresses from competition, raffle, etc.
// Store list of addresses in some data sheeet (Google Sheets or Excel)
let whitelistAddresses = [
	"0x617A4B4f35122293d9d0EA924b17055D4E550c44",
	"0xb00267C61b5d1BC380F1Afa106957697727cC519",
	"0xdc949797bc04d72b2a547d6bd796a51eb15d0a88",
	"0x85aa1db44F588209306fA6A48e4F2ACb0b20189B",
	"0x529e97d40eA063560d16b2a7B14610ea75F16EDf",
	"0x89ed12f746018fdc6ce04c1030dc246e8ba7cc3b",
	"0x6E3EDb79924803e6044704A970406DD3632cAaf9",
	"0x8847239E8d14EF122CFDeC7D0314aD27C3cD0D6b",
	"0xB51367929e153CadC8856695C165CeA08324C349",
	"0x9F322eA2F4Dd8Bbc1e02a7879E74Fb01914192c6",
	"0x8f7bA266AecEBbD2C7D98576cA638949d08BecD3",
	"0x650d492E2F68A665a5dc029bc3f255FE5ec366Fc",
	"0xEDaCA2961edF7592F6d05f5bCB6E0F9BE3787Def",
	"0x103a058412df3A36e5fb7511E637d3e53D91EEea",
	"0x1c28f041e4d70fecb05482a669f019377f1e20bc",
	"0x457a78141F8bEC38037E3886c5160dEC18637c14",
	"0x835B4485955ba8046231275318b579a1165cee2d",
	"0x12E862e0792d22C1B16Ca6F1df878f4Fc006Cf61",
	"0x002F874F745Ecb5b3D7FAe5196B3b037650Cf11d",
	"0x5be840577d46c18971446736578B5d12F60E604F",
	"0x5B22579045aa510d979766A47E58C78453EF13EC",
	"0x636Fb08404366EB0cFB8bda4a23e8B5BdD12ac80",
	"0x52bc7312B2AED57D30dddE7Bf8a3AD05BD1232F8",
	"0x3924bC70C63aA1c4bE7e94004DC869Fa633D3091",
	"0x2BDE2d294D7C775BA37E7B18be40D635A71E8349",
	"0xB4f22A8f58d1Fc1D098254d5C512E725Be85ca03"
];

let whitelistAddresses2 = [];

// 3. Create a new array of `leafNodes` by hashing all indexes of the `whitelistAddresses`
// using `keccak256`. Then creates a Merkle Tree object using keccak256 as the algorithm.
//
// The leaves, merkleTree, and rootHas are all PRE-DETERMINED prior to whitelist claim
const leafNodes = whitelistAddresses.map(addr => keccak256(addr));
const merkleTree = new MerkleTree(leafNodes, keccak256, { sortPairs: true });

const leafNodes2 = whitelistAddresses2.map(addr2 => keccak256(addr2));
const merkleTree2 = new MerkleTree(leafNodes2, keccak256, { sortPairs: true });

// 4. Get root hash of the `merkleeTree` in hexadecimal format (0x)
// Print out the Entire Merkle Tree.
const rootHash = merkleTree.getRoot();
const rootHashHash = merkleTree.getHexRoot();

const rootHash2 = merkleTree2.getRoot();
const rootHashHash2 = merkleTree2.getHexRoot();

const ABI = [
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_initBaseURI",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "_initNotRevealedUri",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "_contractURI",
				"type": "string"
			}
		],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"inputs": [],
		"name": "ApprovalCallerNotOwnerNorApproved",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "ApprovalQueryForNonexistentToken",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "ApprovalToCurrentOwner",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "ApproveToCaller",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "BalanceQueryForZeroAddress",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "MintToZeroAddress",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "MintZeroQuantity",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			}
		],
		"name": "OperatorNotAllowed",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "OwnerIndexOutOfBounds",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "OwnerQueryForNonexistentToken",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TokenIndexOutOfBounds",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferCallerNotOwnerNorApproved",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferFromIncorrectOwner",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferToNonERC721ReceiverImplementer",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferToZeroAddress",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "URIQueryForNonexistentToken",
		"type": "error"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "approved",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Approval",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "ApprovalForAll",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Transfer",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "OPERATOR_FILTER_REGISTRY",
		"outputs": [
			{
				"internalType": "contract IOperatorFilterRegistry",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "accessRate",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "approve",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			}
		],
		"name": "balanceOf",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "contractURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "dailyWithdraw",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "fundReleasingPeriod",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "getApproved",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getBaseURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			}
		],
		"name": "isApprovedForAll",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "lastWithdrawnTime",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "max_per_wallet",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "mint",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "mintedRefundID",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "name",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "notRevealedUri",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "ownerOf",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "projectStartDate",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "publicSaleCost",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "public_mint_status",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "refund",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "refundActivity",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "refundTokenIDWithOwner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "revealed",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_tokenId",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_salePrice",
				"type": "uint256"
			}
		],
		"name": "royaltyInfo",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			},
			{
				"internalType": "bytes",
				"name": "data",
				"type": "bytes"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_newAccessRate",
				"type": "uint256"
			}
		],
		"name": "setAccessRate",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "setApprovalForAll",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_newBaseURI",
				"type": "string"
			}
		],
		"name": "setBaseURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_contractURI",
				"type": "string"
			}
		],
		"name": "setContractURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_fundReleasingPeriod",
				"type": "uint256"
			}
		],
		"name": "setFundReleasingPeriod",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_MAX_SUPPLY",
				"type": "uint256"
			}
		],
		"name": "setMAX_SUPPLY",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_max_per_wallet",
				"type": "uint256"
			}
		],
		"name": "setMax_per_wallet",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_notRevealedURI",
				"type": "string"
			}
		],
		"name": "setNotRevealedURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_publicSaleCost",
				"type": "uint256"
			}
		],
		"name": "setPublicSaleCost",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_receiver",
				"type": "address"
			},
			{
				"internalType": "uint96",
				"name": "_royaltyFeesInBips",
				"type": "uint96"
			}
		],
		"name": "setRoyaltyInfo",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_timeLimit",
				"type": "uint256"
			}
		],
		"name": "setTimeLimit",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_total_ps_limit",
				"type": "uint256"
			}
		],
		"name": "setTotal_ps_limit",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_total_wl_limit",
				"type": "uint256"
			}
		],
		"name": "setTotal_wl_limit",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_newWhitelistCost",
				"type": "uint256"
			}
		],
		"name": "setWhitelistCost",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes32",
				"name": "_newWhitelistSigner",
				"type": "bytes32"
			}
		],
		"name": "setWhitelistSigner",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes4",
				"name": "interfaceId",
				"type": "bytes4"
			}
		],
		"name": "supportsInterface",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "symbol",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "timeLimit",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "toggleRefundActivity",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "toggleReveal",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "toggle_public_mint_status",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "toggle_wl_mint_status",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "index",
				"type": "uint256"
			}
		],
		"name": "tokenByIndex",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "index",
				"type": "uint256"
			}
		],
		"name": "tokenOfOwnerByIndex",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "tokenURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalSupply",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "total_ps_count",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "total_ps_limit",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "total_wl_count",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "total_wl_limit",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "transferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "whitelistCost",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes32[]",
				"name": "_proof",
				"type": "bytes32[]"
			}
		],
		"name": "whitelistMint",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "whitelistSigner",
		"outputs": [
			{
				"internalType": "bytes32",
				"name": "",
				"type": "bytes32"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "wl_mint_status",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}
];

const address = "0x581B88FC7a8659988C3258C924eCC08652511D83";

const biddingABI = [
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_name",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "_symbol",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "_initBaseURI",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "_initNotRevealedUri",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "_contractURI",
				"type": "string"
			},
			{
				"internalType": "address",
				"name": "_nft_Contract",
				"type": "address"
			}
		],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			}
		],
		"name": "OperatorNotAllowed",
		"type": "error"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "approved",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Approval",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "ApprovalForAll",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Transfer",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "MAX_SUPPLY",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "OPERATOR_FILTER_REGISTRY",
		"outputs": [
			{
				"internalType": "contract IOperatorFilterRegistry",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256[]",
				"name": "_puzzleIndex",
				"type": "uint256[]"
			},
			{
				"internalType": "uint256[]",
				"name": "_pieceIDs",
				"type": "uint256[]"
			}
		],
		"name": "PuzzleInput",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "additionalBidValue",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "approve",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			}
		],
		"name": "balanceOf",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "baseExtension",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_id1",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_id9_by_seller",
				"type": "uint256"
			}
		],
		"name": "bid",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "bidCount",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			},
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "bidDeposit",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "bidDepositer",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "bidGap",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "bidList",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "biddingClosingTimeByOwner",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_salePrice",
				"type": "uint256"
			}
		],
		"name": "calculateRoyalty",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "contractURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "deleteData",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			},
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "depositedAlready",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			},
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "depositedAmount",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "getApproved",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getBaseURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "initialBiddingTimeGap",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			}
		],
		"name": "isApprovedForAll",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "listCount",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "max_per_wallet",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "middlePieceID",
				"type": "uint256"
			}
		],
		"name": "mint",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "mutex",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "name",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "nextMinBidValue",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "nft",
		"outputs": [
			{
				"internalType": "contract IERC721",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "nickNameWithWallet",
		"outputs": [
			{
				"internalType": "bytes",
				"name": "",
				"type": "bytes"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "notRevealedUri",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "ownerOf",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "pause",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "publicSaleCost",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "public_mint_status",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "puzzleIndexfinder",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "puzzlePieceIDs",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "revealed",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_tokenId",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_salePrice",
				"type": "uint256"
			}
		],
		"name": "royaltyInfo",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			},
			{
				"internalType": "bytes",
				"name": "data",
				"type": "bytes"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_additionalBidValue",
				"type": "uint256"
			}
		],
		"name": "setAdditionalBidValue",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "setApprovalForAll",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_newBaseExtension",
				"type": "string"
			}
		],
		"name": "setBaseExtension",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_newBaseURI",
				"type": "string"
			}
		],
		"name": "setBaseURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_bidGap",
				"type": "uint256"
			}
		],
		"name": "setBidGap",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_contractURI",
				"type": "string"
			}
		],
		"name": "setContractURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_initialBiddingTimeGap",
				"type": "uint256"
			}
		],
		"name": "setInitialBiddingTimeGap",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_MAX_SUPPLY",
				"type": "uint256"
			}
		],
		"name": "setMAX_SUPPLY",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_max_per_wallet",
				"type": "uint256"
			}
		],
		"name": "setMax_per_wallet",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_nftContract",
				"type": "address"
			}
		],
		"name": "setNFTContract",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes",
				"name": "_nickname",
				"type": "bytes"
			}
		],
		"name": "setNickNameWithWallet",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_notRevealedURI",
				"type": "string"
			}
		],
		"name": "setNotRevealedURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bool",
				"name": "_state",
				"type": "bool"
			}
		],
		"name": "setPause",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_publicSaleCost",
				"type": "uint256"
			}
		],
		"name": "setPublicSaleCost",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bool",
				"name": "_public_mint_status",
				"type": "bool"
			}
		],
		"name": "setPublic_mint_status",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_royaltyAddress",
				"type": "address"
			}
		],
		"name": "setRoyaltyAddress",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_receiver",
				"type": "address"
			},
			{
				"internalType": "uint96",
				"name": "_royaltyFeesInBips",
				"type": "uint96"
			}
		],
		"name": "setRoyaltyInfo",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_biddingClosingTimeByOwner",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "puzzleID",
				"type": "uint256"
			}
		],
		"name": "setbiddingClosingTimeByOwner",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "solvedPuzzles",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes4",
				"name": "interfaceId",
				"type": "bytes4"
			}
		],
		"name": "supportsInterface",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "symbol",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "toggleReveal",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "tokenURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalSupply",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "transferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "winner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "winnerNickname",
		"outputs": [
			{
				"internalType": "bytes",
				"name": "",
				"type": "bytes"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "winningBid",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "winningDepositer",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "winningValue",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "withdraw",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	}
];

const biddingAddress = "0x96FD97FC44d89AA540b7Cd896675d2C68b34ba20";

const ABI3 = [
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_name",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "_symbol",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "_initBaseURI",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "_initNotRevealedUri",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "_contractURI",
				"type": "string"
			},
			{
				"internalType": "address",
				"name": "_nft_bidding_Contract",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "_nft_piece_Contract",
				"type": "address"
			}
		],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			}
		],
		"name": "OperatorNotAllowed",
		"type": "error"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "approved",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Approval",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "ApprovalForAll",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Transfer",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "MAX_SUPPLY",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "OPERATOR_FILTER_REGISTRY",
		"outputs": [
			{
				"internalType": "contract IOperatorFilterRegistry",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256[]",
				"name": "_puzzleIndex",
				"type": "uint256[]"
			},
			{
				"internalType": "uint256[]",
				"name": "_pieceIDs",
				"type": "uint256[]"
			}
		],
		"name": "PuzzleInput",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "approve",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			}
		],
		"name": "balanceOf",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "baseExtension",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_salePrice",
				"type": "uint256"
			}
		],
		"name": "calculateRoyalty",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "contractURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "getApproved",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getBaseURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			}
		],
		"name": "isApprovedForAll",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "max_per_wallet",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_id1",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_id2",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_id3",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_id4",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_id5",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_id6",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_id7",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_id8",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_id9",
				"type": "uint256"
			}
		],
		"name": "mint",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "name",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "nft",
		"outputs": [
			{
				"internalType": "contract IERC721",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "nft2",
		"outputs": [
			{
				"internalType": "contract IERC721",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "notRevealedUri",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "ownerOf",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "publicSaleCost",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "public_mint_status",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "puzzleIndexfinder",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "puzzlePieceIDs",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "revealed",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_tokenId",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_salePrice",
				"type": "uint256"
			}
		],
		"name": "royaltyInfo",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			},
			{
				"internalType": "bytes",
				"name": "data",
				"type": "bytes"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "setApprovalForAll",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_newBaseExtension",
				"type": "string"
			}
		],
		"name": "setBaseExtension",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_newBaseURI",
				"type": "string"
			}
		],
		"name": "setBaseURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_contractURI",
				"type": "string"
			}
		],
		"name": "setContractURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_MAX_SUPPLY",
				"type": "uint256"
			}
		],
		"name": "setMAX_SUPPLY",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_max_per_wallet",
				"type": "uint256"
			}
		],
		"name": "setMax_per_wallet",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_nft_bidding_Contract1",
				"type": "address"
			}
		],
		"name": "setNFTContract",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_nft_piece_Contract2",
				"type": "address"
			}
		],
		"name": "setNFTContract2",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_notRevealedURI",
				"type": "string"
			}
		],
		"name": "setNotRevealedURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_publicSaleCost",
				"type": "uint256"
			}
		],
		"name": "setPublicSaleCost",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bool",
				"name": "_public_mint_status",
				"type": "bool"
			}
		],
		"name": "setPublic_mint_status",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_royaltyAddress",
				"type": "address"
			}
		],
		"name": "setRoyaltyAddress",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_receiver",
				"type": "address"
			},
			{
				"internalType": "uint96",
				"name": "_royaltyFeesInBips",
				"type": "uint96"
			}
		],
		"name": "setRoyaltyInfo",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "solvedPuzzles",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes4",
				"name": "interfaceId",
				"type": "bytes4"
			}
		],
		"name": "supportsInterface",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "symbol",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "toggleReveal",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "tokenURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalSupply",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "transferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "withdraw",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	}
];

const address3 = "0xdD856B186faB9a213e2fCB23c06Dd279A947E07e";

let contract;
let contractBidding;
let contract3;
let nextMinBidValue = 0;

const curtainOpen = () => {

	$(".leftCurtain").animate({
		left: '30%'
	},

		1000,

		function () {
			$(this).addClass("leftCurtainAnimation");

			/*	setTimeout(curtainRemoval, 2000);
				function curtainRemoval() {
					$(".leftCurtain").hide();
					$(".leftCurtainAnimation").hide();
	
				}*/
		}
	);

	$(".rightCurtain").animate({
		right: '30%'
	},

		1000,

		function () {
			$(this).addClass("rightCurtainAnimation");


			/*	setTimeout(curtainRemoval, 2000);
				function curtainRemoval() {
					$(".rightCurtain").hide();
					$(".rightCurtainAnimation").hide();
	
				}*/
		}
	);

	setTimeout(removal, 500);
	function removal() {
		$(".enterBtn").hide();

	}

	$(".spaceCover").animate({
		right: '30%'
	},

		1000,

		function () {
			$(this).hide();
		}
	);
}

class Lelouvre extends Component {

	state = {
		walletAddress: "",
		totalSupply: "",
		currentPrice: "",
		nextPrice: "",
		nextSessionAmount: "",
		onlyLeftAmount: "",
		statusError: false,
		statusLoading: false,
		success: false,
		nftMintingAmount: '1',
		totalValue: "",
		presaleValue: "0",
		maxmint: '',
		_adminPanel: 0,
		_adminWhitelistAddresses: [],
		_adminWhitelistAddresses2: [],
		_maxTokensToBuy: "",
		_testingValue: '',
		_wlMint: '',
		_FinalResult: '',
		_wlMsg: 'Whitelist Mint',
		_mintStatus: '',
		_cost: '',
		_tsr: 0,
		_faq: 0,
		_about: 0,
		_lelouvre: 0,
		_bidding: 0,
		_pic1: 0,
		_pic2: 0,
		_pic3: 0,
		_pic4: 0,
		_pic5: 0,
		_pic6: 0,
		_pic7: 0,
		_pic8: 0,
		_ferrisWheel: 1,
		_bloodBtn: 0,
		_code: "",
		_wrongMsgs: [],
		_correctMsgs: [],
		_msg: "",
		_msgCode: "",
		sound: 0,
		_myTokenIDs: [],
		_myPuzzleIDs: [],
		_elementArray: [],
		_selectedPuzzleID: "",
		_biddingArena: 0,
		_biddingAmount: 0,
		_nextMinBidValue: 0,
		_winningValue: 0,
		_biddingClosingTimeByOwner: 0,
		stateNow: Date.now(),
		_biddingMiddlePiece: "",
		_bidDepositer: "",
		_bidCount: "",
		_bidFullList: [],
		_biddingInfo: 0,
		_biddingNote: 0,
		_gap: 0,
		_nextValue: 0,
		_winner: '',
		_mint: 0,
		_minting: 0,
		_winnerMint: '',
		_mintDialogue: 0,
		_costBidding: '',
		_fullPuzzles: [],
		_puzzlePieceIDs: '',
		_pieceID: [],
		_collectScreen: 0,
		_middlePieceID: '',
		_piece1: '',
		_piece2: '',
		_piece3: '',
		_piece4: '',
		_piece5: '',
		_piece6: '',
		_piece7: '',
		_piece8: '',
		_piece9: '',
		_finalMintingPieces: [],
		_mintingAllowed2: true,
		_bidCount: '',
		_winnerNickname: '',
		_nickNameWithWallet: '',
		_nickNameWithWalletCall: '',
		_nickNameList: [],
		_filteredMiddleList: []

	}

	async componentDidMount() {
		curtainOpen();
	}

	onSubmitMinus = async event => {
		event.preventDefault();

		mintAmount = mintAmount - 1;

		if (mintAmount < 1) {
			mintAmount = 1
		}


		if (owner == account) {
			console.log("owner : " + owner)
			onlyLeft = 800 - totalSupplyNFT;

			if (mintAmount > onlyLeft) {
				mintAmount = onlyLeft;
			}

			valueOfNFTs = mintAmount * 0;
			wMintAmount = mintAmount;

			this.setState({ nftMintingAmount: mintAmount });

			this.setState({ totalValue: valueOfNFTs });
		} else {


			if (totalSupplyNFT < 800) {

				onlyLeft = 10 - publicSale;

				if (mintAmount > onlyLeft) {
					mintAmount = onlyLeft;
				}
				valueOfNFTs = mintAmount * this.state._cost;
				wMintAmount = mintAmount;


				this.setState({ nftMintingAmount: mintAmount });

				this.setState({ totalValue: valueOfNFTs });

			}
		}
	}

	onSubmitPlus = async event => {
		event.preventDefault();

		//,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,
		mintAmount = mintAmount + 1;

		if (owner == account) {
			console.log("owner : " + owner)
			onlyLeft = 800 - totalSupplyNFT;

			if (mintAmount > onlyLeft) {
				mintAmount = onlyLeft;
			}

			valueOfNFTs = mintAmount * 0;
			wMintAmount = mintAmount;


			this.setState({ nftMintingAmount: mintAmount });

			this.setState({ totalValue: valueOfNFTs });
		} else {

			if (totalSupplyNFT < 800) {

				onlyLeft = 10 - publicSale;
				console.log(onlyLeft);

				if (mintAmount > onlyLeft) {
					mintAmount = onlyLeft;
				}
				valueOfNFTs = mintAmount * this.state._cost;
				wMintAmount = mintAmount;

				this.setState({ nftMintingAmount: mintAmount });

				this.setState({ totalValue: valueOfNFTs });

			}
		}
	}

	onSubmit2 = async event => {
		event.preventDefault();

		console.log(this.state.walletAddress);

		try {
			let owner = await contract.methods.owner().call();


			if (account != owner) {


				try {


					console.log("minAmount:" + mintAmount);
					console.log("valueOfNFTs:" + valueOfNFTs);


					this.setState({ statusError: false, statusLoading: true });
					await contract.methods.mint(mintAmount * 1).send({/*gasLimit: 685000, */ from: account, value: this.state.totalValue * 1 });
					this.setState({ statusLoading: false, success: true });
					console.log("Mint Amount :" + this.state.mintAmount);

				} catch (err) {
					this.setState({ errorMassage: "ERROR : " + err.message, statusLoading: false, success: false, statusError: true });
					console.log(err);


				}
			} else {

				try {


					console.log("minAmount:" + mintAmount);
					console.log("valueOfNFTs:" + valueOfNFTs);


					this.setState({ statusError: false, statusLoading: true });
					await contract.methods.mint(mintAmount * 1).send({ gasLimit: 685000, from: account, value: this.state.totalValue * 0 });
					this.setState({ statusLoading: false, success: true });
					console.log("Mint Amount :" + this.state.mintAmount);

				} catch (err) {
					this.setState({ errorMassage: "ERROR : " + err.message, statusLoading: false, success: false, statusError: true });
					console.log(err);


				}






			}
		} catch (err) {

			console.log(err);

		}
	}

	wallet2Connect = async event => {
		event.preventDefault();

		const providerOptions = {
			walletconnect: {
				package: WalletConnectProvider, // required
				options: {
					infuraId: "811915bee3744bd38afcf17ecac0f9a6" // required
				}
			},
			coinbasewallet: {
				package: CoinbaseWalletSDK, // Required
				options: {
					appName: "Aterium Universe", // Required
					infuraId: "811915bee3744bd38afcf17ecac0f9a6", // Required
					rpc: "", // Optional if `infuraId` is provided; otherwise it's required
					chainId: 1, // Optional. It defaults to 1 if not provided
					darkMode: true // Optional. Use dark theme, defaults to false
				}
			}
		};

		const web3Modal = new Web3Modal({
			network: "mainnet", // optional
			cacheProvider: true, // optional
			providerOptions // required
		});



		const provider = await web3Modal.connect();

		//  Enable session (triggers QR Code modal)
		await provider.enable();

		const web3 = new Web3(provider);
		console.log("provider : " + provider);
		// Subscribe to accounts change
		provider.on("accountsChanged", (accounts) => {
			console.log(accounts);
		});

		// Subscribe to chainId change
		provider.on("chainChanged", (chainId) => {
			console.log(chainId);
		});

		// Subscribe to provider connection
		provider.on("connect", (info) => {
			console.log(info);
			console.log("I'm LOGGED IN");
		});

		// Subscribe to provider disconnection
		provider.on("disconnect", (error) => {
			console.log(error);
		});

		// test if wallet is connected
		if (web3Modal.cachedProvider) {
			// connected now you can get accounts
			console.log("web3Modal.cachedProvider :" + web3Modal.cachedProvider);
			console.log("provider :" + provider);

			const accounts = await web3.eth.getAccounts();

			account = accounts[0];
			this.setState({ walletAddress: account });

			contract = new web3.eth.Contract(ABI, address);
			console.log("contract :" + contract);

			if (provider) {


				//	(async () => {

				if (web3Modal.cachedProvider != "walletconnect" && web3Modal.cachedProvider != "coinbasewallet") {

					const chainId = 5;

					if (window.ethereum.networkVersion !== chainId) {
						try {
							await window.ethereum.request({
								method: 'wallet_switchEthereumChain',
								params: [{ chainId: web3.utils.toHex(chainId) }],
							});
						} catch (err) {
							// This error code indicates that the chain has not been added to MetaMask.
							if (err.code === 4902) {
								await window.ethereum.request({
									method: 'wallet_addEthereumChain',
									params: [
										{
											chainName: 'Goerli test network',
											chainId: web3.utils.toHex(chainId),
											nativeCurrency: { name: 'Goerli test network', decimals: 18, symbol: 'GoerliETH' },
											rpcUrls: ['https://goerli.infura.io/v3/'],

											/*chainName: 'Rinkeby Test Network',
											chainId: web3.utils.toHex(chainId),
											nativeCurrency: { name: 'RinkebyETH', decimals: 18, symbol: 'ETH' },
											rpcUrls: ['https://rinkeby.infura.io/v3/'],*/
										},
									],
								});
							}
						}
					}

					try {

						totalSupplyNFT = await contract.methods.totalSupply().call();
						this.setState({ totalSupply: totalSupplyNFT });
						console.log("Total Supply:" + totalSupplyNFT);

						publicSale = await contract.methods.balanceOf(account).call();
						this.setState({ myNFTWallet: publicSale });

						cost = await contract.methods.publicSaleCost().call();
						this.setState({ _cost: cost });
						console.log("cost :" + cost);



						owner = await contract.methods.owner().call();
						console.log("Owner" + owner);

						if (owner == account) {
							console.log("owner : " + owner)
							onlyLeft = 800 - totalSupplyNFT;

							if (mintAmount > onlyLeft) {
								mintAmount = onlyLeft;
							}

							valueOfNFTs = mintAmount * 0;
							wMintAmount = mintAmount;


							this.setState({ nftMintingAmount: mintAmount });

							this.setState({ totalValue: valueOfNFTs });
						} else {
							mintAmount = 1;

							if (totalSupplyNFT == 800) {

								onlyLeft = 800 - totalSupplyNFT;
								mintAmount = onlyLeft;
								this.setState({ msg: "SOLD OUT" });

							} else {
								mintAmount = 1;
								onlyLeft = 10 - publicSale;

								if (mintAmount > onlyLeft) {
									mintAmount = onlyLeft;
								}
								//mintAmount = onlyLeft;

								valueOfNFTs = mintAmount * this.state._cost;
								wMintAmount = mintAmount;


								this.setState({ nftMintingAmount: mintAmount });

								this.setState({ totalValue: valueOfNFTs });
							}
						}


					} catch (err) {
						console.log("err: " + err);

					}


				} else if (web3Modal.cachedProvider == "walletconnect") {

					const chainId = 5;

					if (WalletConnectProvider.networkVersion !== chainId) {
						try {
							await WalletConnectProvider.request({
								method: 'wallet_switchEthereumChain',
								params: [{ chainId: web3.utils.toHex(chainId) }],
							});
						} catch (err) {
							// This error code indicates that the chain has not been added to MetaMask.
							if (err.code === 4902) {
								await window.ethereum.request({
									method: 'wallet_addEthereumChain',
									params: [
										{
											chainName: 'Goerli test network',
											chainId: web3.utils.toHex(chainId),
											nativeCurrency: { name: 'Goerli test network', decimals: 18, symbol: 'GoerliETH' },
											rpcUrls: ['https://goerli.infura.io/v3/'],

											/*chainName: 'Rinkeby Test Network',
											chainId: web3.utils.toHex(chainId),
											nativeCurrency: { name: 'RinkebyETH', decimals: 18, symbol: 'ETH' },
											rpcUrls: ['https://rinkeby.infura.io/v3/'],*/
										},
									],
								});
							}
						}
					}

					try {

						totalSupplyNFT = await contract.methods.totalSupply().call();
						this.setState({ totalSupply: totalSupplyNFT });
						console.log("Total Supply:" + totalSupplyNFT);

						publicSale = await contract.methods.balanceOf(account).call();
						this.setState({ myNFTWallet: publicSale });

						cost = await contract.methods.publicSaleCost().call();
						this.setState({ _cost: cost });
						console.log("cost :" + cost);


						owner = await contract.methods.owner().call();
						console.log("Owner" + owner);

						if (owner == account) {
							console.log("owner : " + owner)
							onlyLeft = 800 - totalSupplyNFT;

							if (mintAmount > onlyLeft) {
								mintAmount = onlyLeft;
							}

							valueOfNFTs = mintAmount * 0;
							wMintAmount = mintAmount;


							this.setState({ nftMintingAmount: mintAmount });

							this.setState({ totalValue: valueOfNFTs });
						} else {
							mintAmount = 1;

							if (totalSupplyNFT == 800) {

								onlyLeft = 800 - totalSupplyNFT;
								mintAmount = onlyLeft;
								this.setState({ msg: "SOLD OUT" });

							} else {
								mintAmount = 1;
								onlyLeft = 10 - publicSale;

								if (mintAmount > onlyLeft) {
									mintAmount = onlyLeft;
								}
								//mintAmount = onlyLeft;

								valueOfNFTs = mintAmount * this.state._cost;
								wMintAmount = mintAmount;


								this.setState({ nftMintingAmount: mintAmount });

								this.setState({ totalValue: valueOfNFTs });
							}
						}


					} catch (err) {
						console.log("err: " + err);

					}

				} else if (web3Modal.cachedProvider == "coinbasewallet") {

					const chainId = 5;

					if (CoinbaseWalletProvider.networkVersion !== chainId) {
						try {
							await CoinbaseWalletProvider.request({
								method: 'wallet_switchEthereumChain',
								params: [{ chainId: web3.utils.toHex(chainId) }],
							});
						} catch (err) {
							// This error code indicates that the chain has not been added to MetaMask.
							if (err.code === 4902) {
								await CoinbaseWalletProvider.request({
									method: 'wallet_addEthereumChain',
									params: [
										{
											chainName: 'Goerli test network',
											chainId: web3.utils.toHex(chainId),
											nativeCurrency: { name: 'Goerli test network', decimals: 18, symbol: 'GoerliETH' },
											rpcUrls: ['https://goerli.infura.io/v3/'],
										},
									],
								});
							}
						}
					}

					try {

						totalSupplyNFT = await contract.methods.totalSupply().call();
						this.setState({ totalSupply: totalSupplyNFT });
						console.log("Total Supply:" + totalSupplyNFT);

						publicSale = await contract.methods.balanceOf(account).call();
						this.setState({ myNFTWallet: publicSale });

						cost = await contract.methods.publicSaleCost().call();
						this.setState({ _cost: cost });
						console.log("cost :" + cost);



						owner = await contract.methods.owner().call();
						console.log("Owner" + owner);

						if (owner == account) {
							console.log("owner : " + owner)
							onlyLeft = 800 - totalSupplyNFT;

							if (mintAmount > onlyLeft) {
								mintAmount = onlyLeft;
							}

							valueOfNFTs = mintAmount * 0;
							wMintAmount = mintAmount;


							this.setState({ nftMintingAmount: mintAmount });

							this.setState({ totalValue: valueOfNFTs });
						} else {
							mintAmount = 1;

							if (totalSupplyNFT == 800) {

								onlyLeft = 800 - totalSupplyNFT;
								mintAmount = onlyLeft;
								this.setState({ msg: "SOLD OUT" });

							} else {
								mintAmount = 1;
								onlyLeft = 10 - publicSale;

								if (mintAmount > onlyLeft) {
									mintAmount = onlyLeft;
								}
								//mintAmount = onlyLeft;

								valueOfNFTs = mintAmount * this.state._cost;
								wMintAmount = mintAmount;


								this.setState({ nftMintingAmount: mintAmount });

								this.setState({ totalValue: valueOfNFTs });
							}
						}


					} catch (err) {
						console.log("err: " + err);

					}


				}


				//})();

				//.....................................................................//

				// Legacy providers may only have ethereum.sendAsync
				const chainId = await provider.request({
					method: 'eth_chainId'
				})

			} else {

				// if the provider is not detected, detectEthereumProvider resolves to null
				console.error('Please install a Valid Wallet');
				alert('A valid provider could not be found!');

			}

		}

	}
	wallet3Connect = async event => {
		event.preventDefault();


		const providerOptions = {
			walletconnect: {
				package: WalletConnectProvider, // required
				options: {
					infuraId: "bf933c3446b3464c988114813a1360ac" // required
				}
			}
		};

		const web3Modal = new Web3Modal({
			network: "mainnet", // optional
			cacheProvider: true, // optional
			providerOptions // required
		});



		const provider = await web3Modal.connect();

		//  Enable session (triggers QR Code modal)
		await provider.enable();

		const web3 = new Web3(provider);
		console.log("provider : " + provider);
		// Subscribe to accounts change
		provider.on("accountsChanged", (accounts) => {
			console.log(accounts);
		});

		// Subscribe to chainId change
		provider.on("chainChanged", (chainId) => {
			console.log(chainId);
		});

		// Subscribe to provider connection
		provider.on("connect", (info) => {
			console.log(info);
		});

		// Subscribe to provider disconnection
		provider.on("disconnect", (error) => {
			console.log(error);
		});

		// test if wallet is connected
		if (web3Modal.cachedProvider) {
			// connected now you can get accounts
			console.log("provider :" + web3Modal.cachedProvider);
			const accounts = await web3.eth.getAccounts();

			account = accounts[0];
			this.setState({ walletAddress: account });

			contract = new web3.eth.Contract(ABI, address);
			console.log("contract :" + contract);

			contractBidding = new web3.eth.Contract(biddingABI, biddingAddress);

			if (provider) {


				(async () => {


					if (web3Modal.cachedProvider != "walletconnect") {


						const chainId = 5;

						if (window.ethereum.networkVersion !== chainId) {
							try {
								await window.ethereum.request({
									method: 'wallet_switchEthereumChain',
									params: [{ chainId: web3.utils.toHex(chainId) }],
								});
							} catch (err) {
								// This error code indicates that the chain has not been added to MetaMask.
								if (err.code === 4902) {
									await window.ethereum.request({
										method: 'wallet_addEthereumChain',
										params: [
											{
												/*chainName: 'Ethereum Mainnet',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Ethereum', decimals: 18, symbol: 'ETH' },
												rpcUrls: ['https://etherscan.io'],*/

												chainName: 'Goerli test network',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Goerli test network', decimals: 18, symbol: 'GoerliETH' },
												rpcUrls: ['https://goerli.infura.io/v3/'],
											},
										],
									});
								}
							}
						}





						try {

							totalSupplyNFT = await contract.methods.totalSupply().call();
							this.setState({ totalSupply: totalSupplyNFT });
							console.log("Total Supply:" + totalSupplyNFT);

							publicSale = await contract.methods.balanceOf(account).call();
							this.setState({ myNFTWallet: publicSale });

							cost = await contract.methods.publicSaleCost().call();
							this.setState({ _cost: cost });
							console.log("cost :" + cost);

							for (let y = 0; y < this.state.myNFTWallet; y++) {
								myTokenIDs[y] = await contract.methods.tokenOfOwnerByIndex(account, y).call();

								myPuzzleIDs[y] = await contractBidding.methods.puzzleIndexfinder(myTokenIDs[y]).call();

								this.setState({ _myTokenIDs: myTokenIDs });
								this.setState({ _myPuzzleIDs: myPuzzleIDs });

							}

							elementArray = [myTokenIDs, myPuzzleIDs];
							this.setState({ _elementArray: elementArray });

							owner = await contract.methods.owner().call();
							console.log("Owner" + owner);

							if (owner == account) {
								console.log("owner : " + owner)
								onlyLeft = 800 - totalSupplyNFT;

								if (mintAmount > onlyLeft) {
									mintAmount = onlyLeft;
								}

								valueOfNFTs = mintAmount * 0;
								wMintAmount = mintAmount;


								this.setState({ nftMintingAmount: mintAmount });

								this.setState({ totalValue: valueOfNFTs });
							} else {
								mintAmount = 1;

								if (totalSupplyNFT == 800) {

									onlyLeft = 800 - totalSupplyNFT;
									mintAmount = onlyLeft;
									this.setState({ msg: "SOLD OUT" });

								} else {
									mintAmount = 1;
									onlyLeft = 10 - publicSale;

									if (mintAmount > onlyLeft) {
										mintAmount = onlyLeft;
									}
									//mintAmount = onlyLeft;

									valueOfNFTs = mintAmount * this.state._cost;
									wMintAmount = mintAmount;


									this.setState({ nftMintingAmount: mintAmount });

									this.setState({ totalValue: valueOfNFTs });
								}
							}


						} catch (err) {
							console.log("err: " + err);

						}
					} else {



						if (WalletConnectProvider.networkVersion !== chainId) {
							try {
								await WalletConnectProvider.request({
									method: 'wallet_switchEthereumChain',
									params: [{ chainId: web3.utils.toHex(chainId) }],
								});
							} catch (err) {
								// This error code indicates that the chain has not been added to MetaMask.
								if (err.code === 4902) {
									await window.ethereum.request({
										method: 'wallet_addEthereumChain',
										params: [
											{
												/*chainName: 'Ethereum Mainnet',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Ethereum', decimals: 18, symbol: 'ETH' },
												rpcUrls: ['https://etherscan.io'],*/

												chainName: 'Goerli test network',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Goerli test network', decimals: 18, symbol: 'GoerliETH' },
												rpcUrls: ['https://goerli.infura.io/v3/'],
											},
										],
									});
								}
							}
						}




						try {

							mintStatus = await contract.methods.paused().call();
							this.setState({ _mintStatus: mintStatus });
							console.log("mintStatus2 :" + mintStatus);


							totalSupplyNFT = await contract.methods.totalSupply().call();
							this.setState({ totalSupply: totalSupplyNFT });

							console.log("Total Supply:" + totalSupplyNFT);

							publicSale = await contract.methods.balanceOf(account).call();
							this.setState({ myNFTWallet: publicSale });

							cost = await contract.methods.publicSaleCost().call();
							this.setState({ _cost: cost });
							console.log("cost :" + cost);

							for (let y = 0; y < this.state.myNFTWallet; y++) {
								myTokenIDs[y] = await contract.methods.tokenOfOwnerByIndex(account, y).call();

								myPuzzleIDs[y] = await contractBidding.methods.puzzleIndexfinder(myTokenIDs[y]).call();

								this.setState({ _myTokenIDs: myTokenIDs });
								this.setState({ _myPuzzleIDs: myPuzzleIDs });

							}

							elementArray = [myTokenIDs, myPuzzleIDs];
							this.setState({ _elementArray: elementArray });

							owner = await contract.methods.owner().call();
							console.log("Owner" + owner);

							if (owner == account) {
								console.log("owner : " + owner)
								onlyLeft = 800 - totalSupplyNFT;

								if (mintAmount > onlyLeft) {
									mintAmount = onlyLeft;
								}

								valueOfNFTs = mintAmount * 0;

								wMintAmount = mintAmount;


								this.setState({ nftMintingAmount: mintAmount });

								this.setState({ totalValue: valueOfNFTs });
							} else {
								mintAmount = 1;

								if (totalSupplyNFT == 800) {

									onlyLeft = 0;

									if (mintAmount > onlyLeft) {
										mintAmount = onlyLeft;
									}
									this.setState({ msg: "SOLD OUT" });

								} else {
									mintAmount = 1;
									onlyLeft = 10 - publicSale;

									if (mintAmount > onlyLeft) {
										mintAmount = onlyLeft;
									}

									valueOfNFTs = mintAmount * this.state._cost;
									wMintAmount = mintAmount;


									this.setState({ nftMintingAmount: mintAmount });

									this.setState({ totalValue: valueOfNFTs });
								}
							}


						} catch (err) {
							console.log("err: " + err);

						}
					}
				})();

				//.....................................................................//






				// Legacy providers may only have ethereum.sendAsync
				const chainId = await provider.request({
					method: 'eth_chainId'
				})

			} else {

				// if the provider is not detected, detectEthereumProvider resolves to null
				console.error('Please install a Valid Wallet');
				alert('A valid provider could not be found!');

			}






		}

	}

	walletDisconnect = async event => {
		event.preventDefault();


		const providerOptions = {
			walletconnect: {
				package: WalletConnectProvider, // required
				options: {
					infuraId: "bf933c3446b3464c988114813a1360ac" // required
				}
			}
		};

		const web3Modal = new Web3Modal({
			network: "mainnet", // optional
			cacheProvider: true, // optional
			providerOptions // required
		});



		// disconnect wallet
		web3Modal.clearCachedProvider();
		window.location.reload();

	}

	whitelistMint = async event => {
		event.preventDefault();


		//	console.log('Whitelist Merkle Tree\n', merkleTree.toString());
		console.log("Root Hash: ", rootHash);
		console.log("Root HashHash: ", rootHashHash);

		// ***** ***** ***** ***** ***** ***** ***** ***** // 

		// CLIENT-SIDE: Use `msg.sender` address to query and API that returns the merkle proof
		// required to derive the root hash of the Merkle Tree

		// ✅ Positive verification of address
		//const claimingAddress = leafNodes[0];
		//console.log("Claiming Address:"+ claimingAddress);
		// ❌ Change this address to get a `false` verification
		const claimingAddress = keccak256(account);

		// `getHexProof` returns the neighbour leaf and all parent nodes hashes that will
		// be required to derive the Merkle Trees root hash.
		const hexProof = merkleTree.getHexProof(claimingAddress);
		console.log("HexProof:" + hexProof);

		// ✅ - ❌: Verify is claiming address is in the merkle tree or not.
		// This would be implemented in your Solidity Smart Contract
		console.log("Final result: " + merkleTree.verify(hexProof, claimingAddress, rootHash));
		FinalResult = merkleTree.verify(hexProof, claimingAddress, rootHash);
		this.setState({ _FinalResult: FinalResult });

		if (FinalResult) {
			try {
				this.setState({ statusError: false, statusLoading: true });
				await contract.methods.whitelistMint(hexProof, wMintAmount).send({ gasLimit: 285000, from: account, value: wMintAmount * 12000000000000000 });
				this.setState({ statusLoading: false, success: true });
				console.log("Mint Amount :" + this.state.wMintAmount);

			} catch (err) {
				this.setState({ errorMassage: "ERROR : " + err.message, statusLoading: false, success: false, statusError: true });
				console.log(err);
			}
		} else {
			wlMsg = "Not Eligible for WL"
			this.setState({ _wlMsg: wlMsg });
		}

	}

	tsr = async event => {
		event.preventDefault();

		this.setState({ _tsr: 1 });
		this.setState({ _mint: 0 });
		this.setState({ _about: 0 });
		this.setState({ _faq: 0 });
		this.setState({ _bidding: 0 });
		this.setState({ _biddingArena: 0 });

	}

	faq = async event => {
		event.preventDefault();

		this.setState({ _faq: 1 });
		this.setState({ _mint: 0 });
		this.setState({ _about: 0 });
		this.setState({ _tsr: 0 });
		this.setState({ _bidding: 0 });
		this.setState({ _biddingInfo: 0 })
		this.setState({ _biddingArena: 0 });
	}

	about = async event => {
		event.preventDefault();

		this.setState({ _about: 1 });
		this.setState({ _mint: 0 });
		this.setState({ _tsr: 0 });
		this.setState({ _faq: 0 });
		this.setState({ _bidding: 0 });
		this.setState({ _biddingInfo: 0 })
		this.setState({ _biddingArena: 0 });

	}

	lelouvre = async event => {
		event.preventDefault();

		this.setState({ _lelouvre: 1 });
		this.setState({ _about: 0 });
		this.setState({ _tsr: 0 });
		this.setState({ _faq: 0 });
		this.setState({ _bidding: 0 });
		this.setState({ _biddingInfo: 0 })
		this.setState({ _biddingArena: 0 });


	}

	async mintPieceM(tokenID_Middle) {
		this.setState({ _minting: 1 });

		try {

			console.log("tokenID_Middle : " + tokenID_Middle);
			let winnerMint = await contractBidding.methods.winner(tokenID_Middle).call();
			this.setState({ _winnerMint: winnerMint });
			console.log("winnerMint : " + winnerMint);
			console.log("account : " + account);

			let biddingClosingTimeByOwnerM = await contractBidding.methods.biddingClosingTimeByOwner(tokenID_Middle).call();

			if (winnerMint == account && Date.now() > (biddingClosingTimeByOwnerM * 1000)) {

				this.setState({ statusError: false, statusLoading: true });
				await contractBidding.methods.mint(tokenID_Middle).send({ gasLimit: 685000, from: account, value: this.state._costBidding * 1 });
				this.setState({ statusLoading: false, success: true });
				console.log("Mint Amount :" + this.state.mintAmount);

			} else {
				this.setState({ _mintDialogue: 1 });
			}

		} catch (err) {
			this.setState({ errorMassage: "ERROR : " + err.message, statusLoading: false, success: false, statusError: true });
			console.log(err);
		}

	}

	async collectScreen(fullPuzzleID) {

		try {
			this.setState({ _collectScreen: 1 });

			this.setState({ _middlePieceID: fullPuzzleID });

			await new Promise(resolve => setTimeout(resolve, 500));

			try {

				ownerOfTheMiddlePieceOwner = await contractBidding.methods.ownerOf(fullPuzzleID).call();
			} catch (err) {
				console.log(err);
			}

			if (ownerOfTheMiddlePieceOwner == account) {

				middlePieceIDUrl = "https://middle100.pages.dev/5/" + fullPuzzleID + ".png";
				console.log("middlePieceIDUrl : " + middlePieceIDUrl);
			} else {

				middlePieceIDUrl = "https://middle100.pages.dev/1001/1001.png";
				mintingAllowed2 = false;
			}

			this.setState({ _mintingAllowed2: mintingAllowed2 });

			for (let x = 0; x < 8; x++) {
				pieceID[x] = await contractBidding.methods.puzzlePieceIDs(fullPuzzleID, x).call();
				pieceID2[x] = [pieceID[x], pieceID[x]];

				console.log("puzzlePieceIDs : " + pieceID[x]);

			}

			for (let x = 0; x < 8; x++) {

				let ownerOfThePiece;

				if (pieceID[x] <= totalSupplyNFT) {

					ownerOfThePiece = await contract.methods.ownerOf(pieceID[x]).call();

					//await new Promise(resolve => setTimeout(resolve, 500));

					if (ownerOfThePiece != account) {
						pieceID2[x] = [pieceID[x], 1001];
						console.log("NOT MATCH");
					}
					//await new Promise(resolve => setTimeout(resolve, 500));
					//this.setState({ _pieceID : pieceID });
				} else {
					pieceID2[x] = [pieceID[x], 1001];
					mintingAllowed2 = false;

				}

				this.setState({ _mintingAllowed2: mintingAllowed2 });

				this.setState({ _pieceID: pieceID2 });
				console.log("ownerOfThePiece : " + ownerOfThePiece);
			}

			let ownerOfTheMiddlePiece;
			ownerOfTheMiddlePiece = await contract.methods.ownerOf(this.state._middlePieceID).call();

		} catch (err) {
			console.log(err);
		}
	}

	mintPiece = async event => {
		event.preventDefault();
		this.setState({ _minting: 1 });

		/*let winnerMint = await contractBidding.methods.winner(this.state._selectedPuzzleID).call();
		this.setState({ _winnerMint: winnerMint });
		console.log("winnerMint : " + winnerMint);*/

	}

	mintPuzzle = async event => {
		event.preventDefault();
		this.setState({ _puzzleNFTS: 1 });

		/*let winnerMint = await contractBidding.methods.winner(this.state._selectedPuzzleID).call();
		this.setState({ _winnerMint: winnerMint });
		console.log("winnerMint : " + winnerMint);*/

	}

	mint = async event => {
		event.preventDefault();

		this.setState({ _mint: 0 });
		this.setState({ _about: 0 });
		this.setState({ _tsr: 0 });
		this.setState({ _faq: 0 });
		this.setState({ _bidding: 0 });
		this.setState({ _biddingInfo: 0 })
		this.setState({ _mint: 1 })
		this.setState({ _minting: 0 });
		this.setState({ _biddingArena: 0 });


	}

	async FinalMinting(ID1, ID2, ID3, ID4, ID5, ID6, ID7, ID8, ID9) {

		try {
			await contract3.methods.mint(ID1, ID2, ID3, ID4, ID5, ID6, ID7, ID8, ID9).send({/*gasLimit: 685000, */ from: account, value: this.state.totalValue * 0 });
		} catch (err) {
			console.log(err);
		}
	}

	mint2 = async event => {
		event.preventDefault();

		this.setState({ _mint: 0 });
		this.setState({ _about: 0 });
		this.setState({ _tsr: 0 });
		this.setState({ _faq: 0 });
		this.setState({ _bidding: 0 });
		this.setState({ _biddingInfo: 0 })
		this.setState({ _mint: 1 })
		this.setState({ _minting: 0 });
		this.setState({ _biddingArena: 0 });


	}

	startBidding = async event => {
		event.preventDefault();

		try {
			await contractBidding.methods.bid(this.state._selectedTokenID, this.state._selectedPuzzleID).send({ gasLimit: 685000, from: account, value: this.state._biddingAmount * 10 ** 18 });
		} catch (err) {
			biddingNote = 1;
			await new Promise(resolve => setTimeout(resolve, 500));
			this.setState({ _biddingNote: biddingNote });
			console.log("I'm in CATCH");
		}

		if (biddingNote == 0) {

			localStorage.removeItem('puzzleID');
			localStorage.removeItem('tokenID');
			await new Promise(resolve => setTimeout(resolve, 500));
			console.log("In the bidding note - 0");
			console.log(
				localStorage?.getItem('puzzleID') + " and " + localStorage?.getItem('tokenID'));


			this.setState({ _biddingArena: 0 });
			this.setState({ _biddingInfo: 1 });
			console.log("biddingNote :" + biddingNote);
			console.log("I'm NOT in CATCH");


		} else {
			this.setState({ _biddingInfo: 0 });
			this.setState({ _biddingArena: 1 });
			console.log("biddingNote :" + biddingNote);

		}


	}

	biddingTxnInfoasync = async event => {
		event.preventDefault();

		this.setState({ _mint: 0 });
		this.setState({ _about: 0 });
		this.setState({ _tsr: 0 });
		this.setState({ _faq: 0 });
		this.setState({ _bidding: 0 });
		this.setState({ _biddingInfo: 1 });

	}


	async biddingP(puzzleID, tokenID) {

		this.setState({ _mint: 0 });
		this.setState({ _about: 0 });
		this.setState({ _tsr: 0 });
		this.setState({ _faq: 0 });
		this.setState({ _biddingArena: 1 });
		this.setState({ _bidding: 0 });
		this.setState({ _biddingInfo: 0 });

		try {

			let puzzleID_1 = localStorage?.getItem('puzzleID');
			let tokenID_2 = localStorage?.getItem('tokenID');

			console.log("puzzleID_1Bidding : " + puzzleID_1);
			console.log("tokenID_2Bidding : " + tokenID_2);


			if (puzzleID_1 > 0) {
				puzzleID = puzzleID_1;
				console.log("puzzleID_1 >= 0");

				//	localStorage.removeItem('puzzleID');

				console.log("insideBiddingP:" + puzzleID);
			}

			if (tokenID_2 > 0) {
				tokenID = tokenID_2;
				console.log("tokenID_2 >= 0");

				//	localStorage.removeItem('tokenID');

				console.log("insideBiddingP:" + tokenID);
			}



		} catch (err) {
			console.log(err);
		}

		this.setState({ _selectedPuzzleID: puzzleID });
		this.setState({ _selectedTokenID: tokenID });

		if (puzzleID != null) {

			biddingMiddlePiece = "https://middle100.pages.dev/5/" + puzzleID + ".png";

		}
		this.setState({ _biddingMiddlePiece: biddingMiddlePiece });
		console.log("biddingMiddlePiece : " + biddingMiddlePiece);


		try {
			/*nextMinBidValue = await contractBidding.methods.nextMinBidValue(this.state._selectedPuzzleID).call();
			let nextMinBidValueLocal = nextMinBidValue / 1000000000000000000;

			if (nextMinBidValue != 0 || nextMinBidValue != undefined) {
				this.setState({ _nextMinBidValue: nextMinBidValueLocal });
			} else {
				this.setState({ _nextMinBidValue: 0.1 });
			}

			console.log("nextMinBidValue :" + nextMinBidValue);*/


			biddingClosingTimeByOwner = await contractBidding.methods.biddingClosingTimeByOwner(puzzleID).call();
			console.log("biddingClosingTimeByOwner999 : " + biddingClosingTimeByOwner);

			let gap1 = await contractBidding.methods.bidGap().call();
			gap = gap1 / 1000000000000000000;
			this.setState({ _gap: gap });
			console.log("gap :" + gap);

			winningValue = await contractBidding.methods.winningValue(puzzleID).call();
			let winningValueLocal = winningValue / 1000000000000000000;
			nextValue = gap * 1 + winningValueLocal * 1;
			this.setState({ _nextValue: nextValue });
			console.log("nextValue : " + nextValue);
			this.setState({ _winningValue: winningValueLocal });
			console.log("winningValue :" + winningValueLocal);


			//let biddingTime = (biddingClosingTimeByOwner * 1000) - Date.now();
			//biddingClosingTimeByOwner = new Date(biddingClosingTimeByOwner);

			bidCount = await contractBidding.methods.bidCount(puzzleID).call();
			this.setState({ _bidCount: bidCount });
			console.log("bidCount : " + bidCount);

			for (let x = 0; x < bidCount; x++) {

				bidDepositedList[x] = await contractBidding.methods.bidDepositer(puzzleID, x).call();
				nickNameList[x] = await contractBidding.methods.nickNameWithWallet(bidDepositedList[x]).call();

				bidAmountsList[x] = await contractBidding.methods.bidList(puzzleID, x).call();
				console.log("bidDepositer :" + bidDepositer);
				console.log("bidDepositer :" + bidDepositer);

				bidFullList[x] = [nickNameList[x], bidAmountsList[x]];
				await new Promise(resolve => setTimeout(resolve, 50));
				this.setState({ _bidFullList: bidFullList.reverse() });
				console.log("ElementArray" + this.state._bidFullList);

			}

			if (biddingClosingTimeByOwner > 0) {
				this.setState({ _biddingClosingTimeByOwner: biddingClosingTimeByOwner * 1000 });
				biddingClosingTimeByOwner = biddingClosingTimeByOwner * 1000;
				console.log("biddingClosingTimeByOwner :" + biddingClosingTimeByOwner);
			}

			localStorage.setItem('puzzleID', puzzleID);
			console.log("puzzleID : " + localStorage?.getItem('puzzleID'));

			localStorage.setItem('tokenID', tokenID);
			console.log("tokenID : " + localStorage?.getItem('tokenID'));

			let winner = await contractBidding.methods.winner(this.state._selectedPuzzleID).call();
			this.setState({ _winner: winner });
			console.log("winner : " + winner);

			let winnerNickname = await contractBidding.methods.winner(this.state._selectedPuzzleID).call();
			this.setState({ _winnerNickname: winnerNickname });
			console.log("winnerNickname : " + winnerNickname);

		} catch (err) {
			console.log(err);
		}

	}

	async biddingPSpecial(puzzleID, tokenID) {

		this.setState({ _biddingArena: 1 });

		try {

			this.setState({ _selectedPuzzleID: puzzleID });
			this.setState({ _selectedTokenID: tokenID });
			biddingMiddlePiece = "https://middle100.pages.dev/5/" + puzzleID + ".png";
			this.setState({ _biddingMiddlePiece: biddingMiddlePiece });
			console.log("biddingMiddlePiece : " + biddingMiddlePiece);


			let puzzleID_1 = localStorage?.getItem('puzzleID');
			let tokenID_2 = localStorage?.getItem('tokenID');

			console.log("puzzleID_1Bidding : " + puzzleID_1);
			console.log("tokenID_2Bidding : " + tokenID_2);

			if (puzzleID_1 > 0) {
				puzzleID = puzzleID_1;
				console.log("puzzleID_1 >= 0");

				//	localStorage.removeItem('puzzleID');

				console.log("insideBiddingP:" + puzzleID);
			}

			if (tokenID_2 > 0) {
				tokenID = tokenID_2;
				console.log("tokenID_2 >= 0");

				//	localStorage.removeItem('tokenID');

				console.log("insideBiddingP:" + tokenID);
			}

		} catch (err) {
			console.log(err);
		}



		try {
			/*nextMinBidValue = await contractBidding.methods.nextMinBidValue(this.state._selectedPuzzleID).call();
			let nextMinBidValueLocal = nextMinBidValue / 1000000000000000000;

			if (nextMinBidValue != 0 || nextMinBidValue != undefined) {
				this.setState({ _nextMinBidValue: nextMinBidValueLocal });
			} else {
				this.setState({ _nextMinBidValue: 0.1 });
			}

			console.log("nextMinBidValue :" + nextMinBidValue);*/

			winningValue = await contractBidding.methods.winningValue(puzzleID).call();
			let winningValueLocal = winningValue / 1000000000000000000;
			this.setState({ _winningValue: winningValueLocal });
			console.log("winningValue :" + winningValueLocal);

			biddingClosingTimeByOwner = await contractBidding.methods.biddingClosingTimeByOwner(puzzleID).call();



			//let biddingTime = (biddingClosingTimeByOwner * 1000) - Date.now();
			//biddingClosingTimeByOwner = new Date(biddingClosingTimeByOwner);

			bidCount = await contractBidding.methods.bidCount(puzzleID).call();

			for (let x = 0; x < bidCount; x++) {

				bidDepositedList[x] = await contractBidding.methods.bidDepositer(puzzleID, x).call();
				nickNameList[x] = await contractBidding.methods.nickNameWithWallet(bidDepositedList[x]).call();


				bidAmountsList[x] = await contractBidding.methods.bidList(puzzleID, x).call();
				console.log("bidDepositer :" + bidDepositer);
				console.log("bidDepositer :" + bidDepositer);

				bidFullList[x] = [nickNameList[x], bidAmountsList[x]];
				await new Promise(resolve => setTimeout(resolve, 50));
				this.setState({ _bidFullList: bidFullList });
				console.log("ElementArray" + this.state._bidFullList);

			}

			if (biddingClosingTimeByOwner > 0) {
				this.setState({ _biddingClosingTimeByOwner: biddingClosingTimeByOwner * 1000 });
				biddingClosingTimeByOwner = biddingClosingTimeByOwner * 1000;
				console.log("biddingClosingTimeByOwner :" + biddingClosingTimeByOwner);
			}

			localStorage.setItem('puzzleID', puzzleID);
			console.log("puzzleID : " + localStorage?.getItem('puzzleID'));

			localStorage.setItem('tokenID', tokenID);
			console.log("tokenID : " + localStorage?.getItem('tokenID'));

		} catch (err) {
			console.log(err);
		}

	}

	bidding = async event => {
		event.preventDefault();

		this.setState({ _mint: 0 });
		this.setState({ _about: 0 });
		this.setState({ _tsr: 0 });
		this.setState({ _faq: 0 });
		this.setState({ _bidding: 1 });
		this.setState({ _biddingInfo: 0 });

		localStorage.removeItem('puzzleID');
		localStorage.removeItem('tokenID');

		//	localStorage.setItem('puzzleID', puzzleID);
		console.log("puzzleIDLocalStorage : " + localStorage?.getItem('puzzleID'));

		//		localStorage.setItem('tokenID', tokenID);
		console.log("tokenIDLocalStorage : " + localStorage?.getItem('tokenID'));

	}

	setNickName = async event => {
		event.preventDefault();

		// Node.js
		const string = this.state._nickName;
		const bytes = Buffer.from(string, 'utf8');

		// Send bytes to contract
		/*await contractBidding.methods.setNickNameWithWallet(bytes).send({ gasLimit: 685000, from: account })
			.then(console.log)
			.catch(console.error);*/


		try {
			//await contractBidding.methods.setNickNameWithWallet(this.state._nickName).send({ gasLimit: 685000, from: account });
			await contractBidding.methods.setNickNameWithWallet(bytes).send({ gasLimit: 685000, from: account });
			this.setState({ _nickNameWithWallet: nickNameWithWallet });
			console.log("nickNameWithWallet : " + nickNameWithWallet);
		} catch (err) {
			console.log(err);
		}
		//window.location.reload();

		console.log("nickNameWithWallet2 : " + this.state._nickName);


	}

	biddingSecondDiv = async event => {
		event.preventDefault();

		this.setState({ _mint: 0 });
		this.setState({ _about: 0 });
		this.setState({ _tsr: 0 });
		this.setState({ _faq: 0 });
		this.setState({ _bidding: 1 });
		this.setState({ _biddingInfo: 0 })

	}

	closeWindow = async event => {
		event.preventDefault();

		this.setState({ _bidding: 0 });
		this.setState({ _minting: 0 });
		this.setState({ _mintDialogue: 0 });
		this.setState({ _puzzleNFTS: 0 });
		this.setState({ _collectScreen: 0 });

	}

	home = async event => {
		event.preventDefault();

		this.setState({ _mint: 0 });
		this.setState({ _about: 0 });
		this.setState({ _tsr: 0 });
		this.setState({ _faq: 0 });
		this.setState({ _bidding: 0 });
		this.setState({ _biddingArena: 0 });

	}

	code = async event => {
		event.preventDefault();

		this.setState({ _wrongMsgs: wrongMsgs });
		this.setState({ _correctMsgs: correctMsgs });

		if (this.state._code == "52") {
			this.setState({ _msg: "You can count. Don't be so proud. Still, tell the Cartoonist. He'd be interested." });
			this.setState({ _msgCode: 1 });

		} else

			if (this.state._code == "George Elliot") {
				this.setState({ _msg: "Huh. Didn't expect you to figure that out. But anyways, do you read?" });
				this.setState({ _msgCode: 1 });

			} else

				if (this.state._code == "Middlemarch") {
					this.setState({ _msg: "It's always the last breath that draws the most meaning. Published by New York and Boston H. M. Caldwell Company Publishers." });
					this.setState({ _msgCode: 1 });

				}
				else

					if (this.state._code == "But the effect of her being on those around her was incalculably diffusive: for the growing good of the world is partly dependent on unhistoric acts; and that things are not so ill with you and me as they might have been, is half owing to the number who lived faithfully a hidden life, and rest in unvisited tombs") {
						this.setState({ _msg: "Long one wasn't it? That one took you years to write. Almost like you gave birth. But you didn't, someone else did. #=years" });
						this.setState({ _msgCode: 1 });

					}

					else

						if (this.state._code == "Wu Yuanji") {
							this.setState({ _msg: "I love bold, italics, and underline. But my FIRST option would be italics." });
							this.setState({ _msgCode: 1 });

						}

						else

							if (this.state._code == "De facto") {
								this.setState({ _msg: "Wow. You're good. Go tell the Cartoonist the last thing you wrote. You are a star." });
								this.setState({ _msgCode: 1 });

							}
							else {

								let e = Math.floor(Math.random() * 50);
								this.setState({ _msg: this.state._wrongMsgs[e] });
								this.setState({ _msgCode: 0 });
								console.log("msg: " + this.state._msg);
							}

	}

	start = async event => {
		event.preventDefault();

		soundfinder = 1;
		this.setState({ sound: soundfinder });
		song = new Audio(audio);
		song.play();
	}

	pause = async event => {
		event.preventDefault();

		soundfinder = 0;
		this.setState({ sound: soundfinder });
		song.pause();
	}

	render() {
		return (

			<div class="boxWrap2Main">

				<div class="headers">

					<div class="logoDiv"></div>

					<div class="right">
						<div class="connect">

							<button class="connectbtn" onClick={() => window.location.href = '/home'}>HOME</button>
						</div>
						<div class="discord"><img class="osPic" onClick={"#"} src={discord} /></div>
						<div class="discord"><img class="osPic" onClick={"#"} src={twitter} /></div>
					</div>

					<div class="rightAbout">
						<div class="connectAbout">

							<button class="connectbtn" onClick={() => window.location.href = '/home'}>HOME</button>

						</div>

					</div>
				</div>
				<div class="boxWrap2">
					<div class="storyRight">
						<div class="storyH2">Le Louvre
						</div>

						<div class="vidCont2">
							<video class="videoBg" src={video2} autobuffer autoPlay loop muted playsInline></video></div>

						<div class="storyRight2-2">
							<div class="about1">
								<p class="storyCon"><span class="bigWord">H</span>ere is where puzzle holders place their puzzles to complete Full Pictures (available after public sale).</p>

							</div>
							<div class="about2">

							</div>
						</div>

						<div class="hoverPics"></div>

						<div class="storyRight2-2">
							<div class="about1">
								<p class="storyCon"><span class="bigWord">T</span>his is where minting for all types of pieces occurs.</p>


							</div>

						</div>
					</div>

					{this.state.myNFTWallet > 0 ?
						(<div class="mintMain">
							<button class="mintBtns" onClick={this.mintPuzzle}>Puzzle NFTs</button>
							<button class="mintBtns" onClick={this.mintPiece}>Middle Piece NFTs</button>
						</div>) : null}

					<div class="nftblockWalletConnectedALL">

						{this.state.walletAddress === '' ? (

							<div class="walletConnect">
								<form onSubmit={this.wallet2Connect}>
									<button class="mintBtns" type='submit'>MINT NOW</button>
								</form>
							</div>
						) :

							(

								<div class="mintDiv">
									<div class="totalSupply">{this.state.totalSupply}/800</div>
									<div class="price"><div>Mint Price {this.state._cost / 1000000000000000000} ETH</div></div>

									<div class="minting_count_button">
										<div>
											<form onSubmit={this.onSubmitMinus}>
												<button class="btnfos-0-2" type="submit">-</button>
											</form>
										</div>

										<div>
											<div class="nftminter2"><span class="nftamount">{this.state.nftMintingAmount}</span></div>
										</div>


										<div>
											<form onSubmit={this.onSubmitPlus}>
												<button class="btnfos-0-2-2" type="submit">+</button>
											</form>
										</div>
									</div>

									{this.state._nickNameWithWalletCall === "" ?
										(<div class="mintbuttondiv">
											{this.state._mintStatus === true ? (
												<div>

													{this.state._FinalResult === true ? (
														<form onSubmit={this.whitelistMint}>
															<button class="btnfos-0-3" type="submit">
																Whitelist Mint</button>
														</form>
													) : (
														<form onSubmit={this.whitelistMint}>
															<button class="btnfos-0-3" type="submit">
																{this.state._wlMsg}</button>
														</form>)}
												</div>
											) : (<form onSubmit={this.onSubmit2}>
												<button class="btnfos-0-3" type="submit">
													Mint Puzzle Pieces</button>
											</form>)}

										</div>) :
										(<div class="mintbuttondiv">
											{this.state._mintStatus === true ? (
												<div>

													{this.state._FinalResult === true ? (
														<form onSubmit={this.whitelistMint}>
															<button class="btnfos-0-3" type="submit">
																Whitelist Mint</button>
														</form>
													) : (
														<form onSubmit={this.whitelistMint}>
															<button class="btnfos-0-3" type="submit">
																{this.state._wlMsg}</button>
														</form>)}
												</div>
											) : (<form onSubmit={this.onSubmit2}>
												<button disabled class="btnfos-0-3-Dis" type="submit">
													Mint Puzzle Pieces</button>
											</form>)}

											<div class="nicknameAndImg">
												<form onSubmit={this.setNickName}>
													<input class="nickName" name="yourName" placeholder="Submit your NickName" value={this.state._nickName}
														onChange={event => this.setState({ _nickName: event.target.value })}>

													</input>
													<button><img class="login" src={login} /></button></form>
											</div>

										</div>)}

									<div>
										{this.state.statusError ? (
											<div class="errorMessage">
												<div >Sorry, something went wrong please try again later</div>
											</div>)
											: null}

										{this.state.statusLoading ? (
											<div class="loadingContainer">
												<div>
													<div class="loadingText">Minting your Puzzle Piece</div>
													<div class="loadTextSub">(this can take up to 30 seconds)</div>
												</div>
											</div>)
											: null}

										{this.state.success ? (

											<div><div class="successfully">MINTING SUCCESSFUL!</div>
											</div>)
											: null}

									</div></div>
							)}
					</div>

				</div>

			</div>
		)
	}
}

export default Lelouvre;

