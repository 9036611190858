import React, { Component, useEffect } from "react";
import "../App.css";
import "aos/dist/aos.css";
import Aos from "aos";
import discord from "../assets/discord.png";
import twitter from "../assets/twitter.png";
import insta from "../assets/instagram.png";
import Footer from "./footer";
import paper from "../assets/paper.png";
import paper1 from "../assets/paper2.png";
import paper2 from "../assets/paper3.png";
import paper3 from "../assets/paper4.png";
import paper4 from "../assets/paper5.png";
import paperNew from "../assets/paper6.png";
import man from "../assets/Unmasked.jpeg";
import audio from "../audio/thanos.mp3";
import bloodBtn from "../assets/bloodBtn.png";
import happyDevil from "../assets/devilHappy.png";
import angryDevil from "../assets/devilAngry.png";
import arrow from "../assets/arrow-head-to-the-right.png";
import back from "../assets/turn-back.png";
import $ from "jquery";

$(function () {
  try {
    var isDrawing, lastPoint;
    var container = document.getElementById("js-container");

    var screenWidth = window.innerWidth;
    if (screenWidth > 499) {
      var canvas = document.getElementById("js-canvas");
    } else {
      var canvas = document.getElementById("js-canvas-mobile");
    }

    var canvasWidth = canvas.width,
      canvasHeight = canvas.height,
      ctx = canvas.getContext("2d"),
      image = new Image(),
      brush = new Image();

    // base64 Workaround because Same-Origin-Policy
    image.src = "https://cartoonistsample.pages.dev/zeebra.jpg";
    image.onload = function () {
      ctx.drawImage(image, 0, 0);
      // Show the form when Image is loaded.
      document.querySelectorAll(".form")[0].style.visibility = "visible";
    };
    brush.src = "https://cartoonistsample.pages.dev/scarchimagetrans.png";

    canvas.addEventListener("mousedown", handleMouseDown, false);
    canvas.addEventListener("touchstart", handleTouchStart, false);
    canvas.addEventListener("mousemove", handleMouseMove, false);
    canvas.addEventListener("touchmove", handleTouchMove, false);
    canvas.addEventListener("mouseup", handleMouseUp, false);
    canvas.addEventListener("touchend", handleMouseUp, false);

    //............................//

    function handleTouchStart(e) {
      // Get the first touch point
      var touch = e.touches[0];
      isDrawing = true;
      lastPoint = getTouch(touch, canvas);
    }

    function handleTouchMove(e) {
      if (!isDrawing) {
        return;
      }

      e.preventDefault();

      var currentPoint = getTouch(e, canvas),
        dist = distanceBetween(lastPoint, currentPoint),
        angle = angleBetween(lastPoint, currentPoint),
        x,
        y;

      for (var i = 0; i < dist; i++) {
        x = lastPoint.x + Math.sin(angle) * i - 25;
        y = lastPoint.y + Math.cos(angle) * i - 25;
        ctx.globalCompositeOperation = "destination-out";
        ctx.drawImage(brush, x, y);
      }

      lastPoint = currentPoint;
      handlePercentage(getFilledInPixels(32));
    }

    function getTouch(e, canvas) {
      var rect = canvas.getBoundingClientRect(),
        mx = (e.pageX || e.touches[0].clientX) - rect.left,
        my = (e.pageY || e.touches[0].clientY) - rect.top;

      return { x: mx, y: my };
    }

    //............................//

    function distanceBetween(point1, point2) {
      return Math.sqrt(
        Math.pow(point2.x - point1.x, 2) + Math.pow(point2.y - point1.y, 2)
      );
    }

    function angleBetween(point1, point2) {
      return Math.atan2(point2.x - point1.x, point2.y - point1.y);
    }

    // Only test every `stride` pixel. `stride`x faster,
    // but might lead to inaccuracy
    function getFilledInPixels(stride) {
      if (!stride || stride < 1) {
        stride = 1;
      }

      var pixels = ctx.getImageData(0, 0, canvasWidth, canvasHeight),
        pdata = pixels.data,
        l = pdata.length,
        total = l / stride,
        count = 0;

      // Iterate over all pixels
      for (var i = (count = 0); i < l; i += stride) {
        if (parseInt(pdata[i]) === 0) {
          count++;
        }
      }

      return Math.round((count / total) * 100);
    }

    function getMouse(e, canvas) {
      var offsetX = 0,
        offsetY = 0,
        mx,
        my;

      if (canvas.offsetParent !== undefined) {
        do {
          offsetX += canvas.offsetLeft;
          offsetY += canvas.offsetTop;
        } while ((canvas = canvas.offsetParent));
      }

      mx = (e.pageX || e.touches[0].clientX) - offsetX;
      my = (e.pageY || e.touches[0].clientY) - offsetY;

      return { x: mx, y: my };
    }

    function handlePercentage(filledInPixels) {
      filledInPixels = filledInPixels || 0;
      console.log(filledInPixels + "%");
      if (filledInPixels > 50) {
        canvas.parentNode.removeChild(canvas);
      }
    }

    function handleMouseDown(e) {
      isDrawing = true;
      lastPoint = getMouse(e, canvas);
    }

    function handleMouseMove(e) {
      if (!isDrawing) {
        return;
      }

      e.preventDefault();

      var currentPoint = getMouse(e, canvas),
        dist = distanceBetween(lastPoint, currentPoint),
        angle = angleBetween(lastPoint, currentPoint),
        x,
        y;

      for (var i = 0; i < dist; i++) {
        x = lastPoint.x + Math.sin(angle) * i - 25;
        y = lastPoint.y + Math.cos(angle) * i - 25;
        ctx.globalCompositeOperation = "destination-out";
        ctx.drawImage(brush, x, y);
      }

      lastPoint = currentPoint;
      handlePercentage(getFilledInPixels(32));
    }

    function handleMouseUp(e) {
      isDrawing = false;
    }
  } catch (err) {
    console.log(err);
  }
});

const afterClick = () => {
  $(".carriage").addClass("afterClick");
};

const afterClick1 = () => {
  $(".carriage1").addClass("afterClick");
};

const afterClick2 = () => {
  $(".carriage2").addClass("afterClick");
};

const afterClick3 = () => {
  $(".carriage3").addClass("afterClick");
};

const afterClick4 = () => {
  $(".carriage4").addClass("afterClick");
};

const afterClick5 = () => {
  $(".carriage5").addClass("afterClick");
};

const afterClick6 = () => {
  $(".carriage6").addClass("afterClick");
};

const afterClick7 = () => {
  $(".carriage7").addClass("afterClick");
};

const Stry = () => {
  useEffect(() => {
    Aos.init({ duration: 4000 });
  }, []);
};

let soundfinder;
let song;
let wrongMsgs = [
  "Pat ordered a ghost pepper pie.",
  "This made him feel like an old-style rootbeer float smells.",
  "Happiness can be found in the depths of chocolate pudding.",
  "He was sitting in a trash can with high street class.",
  "The tears of a clown make my lipstick run, but my shower cap is still intact.",
  "I think I will buy the red car, or I will lease the blue one.",
  "Nancy was proud that she ran a tight shipwreck.",
  "He shaved the peach to prove a point.",
  "The stench from the feedlot permeated the car despite having the air conditioning on recycled air.",
  "It didn't make sense unless you had the power to eat colors.",
  "I became paranoid that the school of jellyfish was spying on me.",
  "It was difficult for Mary to admit that most of her workout consisted of exercising poor judgment.",
  "It was the best sandcastle he had ever seen.",
  "It's never comforting to know that your fate depends on something as unpredictable as the popping of corn.",
  "Art doesn't have to be intentional.",
  "He didn't heed the warning and it had turned out surprisingly well.",
  "I want to buy a onesie… but know it won’t suit me.",
  "His son quipped that power bars were nothing more than adult candy bars.",
  "He realized there had been several deaths on this road, but his concern rose when he saw the exact number.",
  "It isn't true that my mattress is made of cotton candy.",
  "Now I need to ponder my existence and ask myself if I'm truly real",
  "It was always dangerous to drive with him since he insisted the safety cones were a slalom course.",
  "Even with the snow falling outside, she felt it appropriate to wear her bikini.",
  "He liked to play with words in the bathtub.",
  "The heat You've been eyeing me all day and waiting for your move like a lion stalking a gazelle in a savannah.",
  "For the 216th time, he said he would quit drinking soda after this last Coke.",
  "She discovered van life is difficult with 2 cats and a dog.",
  "The sign said there was road work ahead so he decided to speed up.",
  "With a single flip of the coin, his life changed forever.",
  "Grape jelly was leaking out the hole in the roof.",
  "We should play with legos at camp.",
  "It doesn't sound like that will ever be on my travel list.",
  "Improve your goldfish's physical fitness by getting him a bicycle.",
  "The llama couldn't resist trying the lemonade.",
  "The toddler’s endless tantrum caused the entire plane anxiety.",
  "It was obvious she was hot, sweaty, and tired.",
  "The hawk didn’t understand why the ground squirrels didn’t want to be his friend.",
  "If I don’t like something, I’ll stay away from it.",
  "Carol drank the blood as if she were a vampire.",
  "I'm confused: when people ask me what's up, and I point, they groan.",
  "The two walked down the slot canyon oblivious to the sound of thunder in the distance.",
  "He said he was not there yesterday; however, many people saw him there.",
  "The beauty of the African sunset disguised the danger lurking nearby.",
  "She was disgusted he couldn’t tell the difference between lemonade and limeade.",
  "This book is sure to liquefy your brain.",
  "He dreamed of eating green apples with worms.",
  "Honestly, I didn't care much for the first season, so I didn't bother with the second.",
  "The glacier came alive as the climbers hiked closer.",
  "Two more days and all his problems would be solved.",
  "The miniature pet elephant became the envy of the neighborhood.",
  "Having no hair made him look even hairier.",
];

let correctMsgs = [
  "You can count. Don't be so proud. Still, tell the Cartoonist. He'd be interested.",
  "Huh. Didn't expect you to figure that out. But anyways, do you read?",
  "It's always the last breath that draws the most meaning. Published by New York and Boston H. M. Caldwell Company Publishers.",
  "Long one wasn't it? That one took you years to write. Almost like you gave birth. But you didn't, someone else did. #=years",
  "I love bold, italics, and underline. But my FIRST option would be italics.",
  "Wow. You're good. Go tell the Cartoonist the last thing you wrote. You are a star.",
];

class Scratch extends Component {
  state = {
    sound: 0,
    _ferrisWheel: 1,
    _bloodBtn: 0,
    _code: "",
    _wrongMsgs: [],
    _correctMsgs: [],
    _msg: "",
    _msgCode: "",
    _pic1: 0,
    _pic2: 0,
    _pic3: 0,
    _pic4: 0,
    _pic5: 0,
    _pic6: 0,
    _pic7: 0,
    _pic8: 0,
    sound: 0,
  };

  pic1 = async (event) => {
    event.preventDefault();

    this.setState({ _pic1: 1 });

    await new Promise((resolve) => setTimeout(resolve, 250));

    this.ferrisWheel();

    afterClick();
  };

  pic2 = async (event) => {
    event.preventDefault();

    this.setState({ _pic2: 1 });

    await new Promise((resolve) => setTimeout(resolve, 250));

    this.ferrisWheel();

    afterClick1();
  };

  pic3 = async (event) => {
    event.preventDefault();

    this.setState({ _pic3: 1 });
    await new Promise((resolve) => setTimeout(resolve, 250));

    this.ferrisWheel();

    afterClick2();
  };

  pic4 = async (event) => {
    event.preventDefault();

    this.setState({ _pic4: 1 });
    await new Promise((resolve) => setTimeout(resolve, 250));

    this.ferrisWheel();

    afterClick3();
  };

  pic5 = async (event) => {
    event.preventDefault();

    this.setState({ _pic5: 1 });
    await new Promise((resolve) => setTimeout(resolve, 250));

    this.ferrisWheel();

    afterClick4();
  };

  pic6 = async (event) => {
    event.preventDefault();

    this.setState({ _pic6: 1 });
    await new Promise((resolve) => setTimeout(resolve, 250));

    this.ferrisWheel();

    afterClick5();
  };

  pic7 = async (event) => {
    event.preventDefault();

    this.setState({ _pic7: 1 });
    await new Promise((resolve) => setTimeout(resolve, 250));

    this.ferrisWheel();

    afterClick6();
  };

  pic8 = async (event) => {
    event.preventDefault();

    this.setState({ _pic8: 1 });
    await new Promise((resolve) => setTimeout(resolve, 250));

    this.ferrisWheel();

    afterClick7();
  };

  bloodBtn = async (event) => {
    event.preventDefault();

    this.setState({ _bloodBtn: 1 });
  };

  code = async (event) => {
    event.preventDefault();

    this.setState({ _wrongMsgs: wrongMsgs });
    this.setState({ _correctMsgs: correctMsgs });

    if (this.state._code == "52") {
      this.setState({
        _msg: "You can count. Don't be so proud. Still, tell the Cartoonist. He'd be interested.",
      });
      this.setState({ _msgCode: 1 });
    } else if (this.state._code == "George Elliot") {
      this.setState({
        _msg: "Huh. Didn't expect you to figure that out. But anyways, do you read?",
      });
      this.setState({ _msgCode: 1 });
    } else if (this.state._code == "Middlemarch") {
      this.setState({
        _msg: "It's always the last breath that draws the most meaning. Published by New York and Boston H. M. Caldwell Company Publishers.",
      });
      this.setState({ _msgCode: 1 });
    } else if (
      this.state._code ==
      "But the effect of her being on those around her was incalculably diffusive: for the growing good of the world is partly dependent on unhistoric acts; and that things are not so ill with you and me as they might have been, is half owing to the number who lived faithfully a hidden life, and rest in unvisited tombs"
    ) {
      this.setState({
        _msg: "Long one wasn't it? That one took you years to write. Almost like you gave birth. But you didn't, someone else did. #=years",
      });
      this.setState({ _msgCode: 1 });
    } else if (this.state._code == "Wu Yuanji") {
      this.setState({
        _msg: "I love bold, italics, and underline. But my FIRST option would be italics.",
      });
      this.setState({ _msgCode: 1 });
    } else if (this.state._code == "De facto") {
      this.setState({
        _msg: "Wow. You're good. Go tell the Cartoonist the last thing you wrote. You are a star.",
      });
      this.setState({ _msgCode: 1 });
    } else {
      let e = Math.floor(Math.random() * 50);
      this.setState({ _msg: this.state._wrongMsgs[e] });
      this.setState({ _msgCode: 0 });
      console.log("msg: " + this.state._msg);
    }
  };

  async ferrisWheel() {
    if (
      this.state._pic1 == 1 &&
      this.state._pic2 == 1 &&
      this.state._pic3 == 1 &&
      this.state._pic4 == 1 &&
      this.state._pic5 == 1 &&
      this.state._pic6 == 1 &&
      this.state._pic7 == 1 &&
      this.state._pic8 == 1
    ) {
      this.setState({ _ferrisWheel: 0 });
    }

    console.log("pic1 : " + this.state._pic1);
    console.log("pic2 : " + this.state._pic2);
    console.log("pic3 : " + this.state._pic3);
    console.log("pic4 : " + this.state._pic4);
    console.log("pic5 : " + this.state._pic5);
    console.log("pic6 : " + this.state._pic6);
    console.log("pic7 : " + this.state._pic7);
    console.log("pic8 : " + this.state._pic8);
  }

  start = async (event) => {
    event.preventDefault();

    soundfinder = 1;
    this.setState({ sound: soundfinder });
    song = new Audio(audio);
    song.play();
  };

  pause = async (event) => {
    event.preventDefault();

    soundfinder = 0;
    this.setState({ sound: soundfinder });
    song.pause();
  };

  close = async (event) => {
    event.preventDefault();

    this.setState({ _bloodBtn: 0 });
  };

  render() {
    return (
      <div>
        <div class="headers">
          <div class="logoDiv"></div>

          <div class="right">
            <div class="connect">
              <button
                class="connectbtn"
                onClick={() => (window.location.href = "/home")}
              >
                HOME
              </button>
            </div>
            <div class="discord">
              <img class="osPic" onClick={"#"} src={discord} />
            </div>
            <div class="discord">
              <img class="osPic" onClick={"#"} src={twitter} />
            </div>
          </div>

          <div class="rightAbout">
            <div class="connectAbout">
              <button
                class="connectbtn"
                onClick={() => (window.location.href = "/home")}
              >
                HOME
              </button>
            </div>
          </div>
        </div>
        <div class="boxWrap2">
          <div class="storyRight">
            <div class="storyH2">About</div>

            <div class="storyRight2-2">
              {this.state._ferrisWheel > 0 ? (
                <div class="about2">
                  <div class="man">
                    <div class="wrap-outer">
                      <div class="wrapper">
                        <div class="out-wrap">
                          <div class="circle-outer"></div>
                          <div onClick={this.pic1} class="carriage"></div>
                          <div class="bar"></div>
                        </div>
                        <div class="out-wrap">
                          <div class="circle-outer"></div>
                          <div onClick={this.pic2} class="carriage1"></div>
                          <div class="bar"></div>
                        </div>
                        <div class="out-wrap">
                          <div class="circle-outer"></div>
                          <div onClick={this.pic3} class="carriage2"></div>
                          <div class="bar"></div>
                        </div>
                        <div class="out-wrap">
                          <div class="circle-outer"></div>
                          <div onClick={this.pic4} class="carriage3"></div>
                          <div class="bar"></div>
                        </div>
                        <div class="out-wrap">
                          <div class="circle-outer"></div>
                          <div onClick={this.pic5} class="carriage4"></div>
                          <div class="bar"></div>
                        </div>
                        <div class="out-wrap">
                          <div class="circle-outer"></div>
                          <div onClick={this.pic6} class="carriage5"></div>
                          <div class="bar"></div>
                        </div>
                        <div class="out-wrap">
                          <div class="circle-outer"></div>
                          <div onClick={this.pic7} class="carriage6"></div>
                          <div class="bar"></div>
                        </div>
                        <div class="out-wrap">
                          <div class="circle-outer"></div>
                          <div onClick={this.pic8} class="carriage7"></div>
                          <div class="bar"></div>
                        </div>
                      </div>
                      <div class="circle-center"></div>
                      <div class="circle-ring"></div>
                      <div class="triangle"></div>
                    </div>
                  </div>
                  <div class="quotesAndAudio" style={{ marginTop: "30vh" }}>
                    <div class="container" id="js-container">
                      <canvas
                        class="canvas"
                        id="js-canvas"
                        width="800"
                        height="300"
                      ></canvas>
                      <canvas
                        class="canvas"
                        id="js-canvas-mobile"
                        width="600"
                        height="200"
                      ></canvas>

                      <form class="form" style={{ visibility: "hidden" }}>
                        <img class="paperPink" src={paperNew} />
                        <div class="audioPic">
                          {this.state.sound === 0 ? (
                            <div>
                              <button
                                onClick={this.start}
                                class="speakerplace2"
                              ></button>
                            </div>
                          ) : (
                            <div>
                              <button
                                onClick={this.pause}
                                class="speakerplace1"
                              ></button>
                            </div>
                          )}
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              ) : (
                <div class="blodDiv">
                  <img onClick={this.bloodBtn} src={bloodBtn} />
                </div>
              )}
            </div>
          </div>
        </div>

        {this.state._bloodBtn > 0 ? (
          <div class="secretsMain">
            <div class="secrets">
              <div class="secretsT">Chamber Of Secrets</div>

              <div class="msg">{this.state._msg}</div>

              <form onSubmit={this.code}>
                <div class="inputAndSubmit">
                  <input
                    type="text"
                    class="comment"
                    name="yourName"
                    placeholder=". . ."
                    value={this.state._code}
                    onChange={(event) =>
                      this.setState({ _code: event.target.value })
                    }
                  ></input>
                  <button type="submit">
                    <img src={arrow} />{" "}
                  </button>
                </div>
              </form>

              {this.state._msgCode === 0 ? (
                <div class="devil">
                  <img src={angryDevil} />
                </div>
              ) : (
                <div class="devil">
                  <img src={happyDevil} />
                </div>
              )}

              {/* <div class="back">
                <img class="back" style={{zIndex:  10000000}} onClick={this.close} src={back} />
              </div> */}
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default Scratch;
