import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
  
const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}


class Enter extends Component {
    
    render() {
        return (

            <div class="boxWrap2Main">
          
                	<div class="mainCurtain" >
                    <button class="enterBtn" onClick={() => window.location.href = '/home'}>ENTER</button>

					<div class="leftCurtain"></div>

					<div class="rightCurtain"></div>
					</div> 
                  
            </div>
        )
    }
}

export default Enter;

